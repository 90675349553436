/* values 4 */
.corteromboclip {
  clip-path: polygon(50% 100%, 100% 75%, 100% 25%, 50% 0, 0 25%, 0 75%);

}

.corteromboclip2 {
  clip-path: polygon(50% 100%, 100% 75%, 100% 25%, 50% 0, 0 25%, 0 75%, 50% 100%, 50% 98%, 2% 74%, 2% 26%, 50% 2%, 98% 26%, 98% 74%, 50% 98%);
}

.romboClip{
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}